import React, { FC, useRef } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import useClickOutside from 'hooks/useClickOutside';
import useScreenRecognition from 'hooks/useScreenRecognition';

import DefaultPopupContent from './components/DefaultPopupContent';
import DiscountPopupContent from './components/DiscountPopupContent';
import { POPUP_CONTENT_VARIANT } from './constants';
import { ISignUpPopupProps } from './model';
import { useDefaultPopupBehavior, useBrowserSession } from './hooks';

import './SignUpPopup.scss';

const SignUpPopup: FC<ISignUpPopupProps> = ({
  isWithoutSessionMode,
  theme,
  withBrowserSession,
  delayTimeout,
  ...restPopupProps
}) => {
  const [isVisible, onCloseModal] = withBrowserSession
    ? useBrowserSession(delayTimeout)
    : useDefaultPopupBehavior(isWithoutSessionMode, delayTimeout);

  const containerRef = useRef();
  const screen = useScreenRecognition();
  useClickOutside(containerRef, onCloseModal);

  const POPUP_CONTENT_VARIANTS = {
    [POPUP_CONTENT_VARIANT.DEFAULT]: DefaultPopupContent,
    [POPUP_CONTENT_VARIANT.DISCOUNT]: DiscountPopupContent,
  };

  const PopupContent =
    POPUP_CONTENT_VARIANTS[theme] || POPUP_CONTENT_VARIANTS[POPUP_CONTENT_VARIANT.DEFAULT];

  return isVisible ? (
    <section
      className={classNames('signup-popup', {
        [`signup-popup--${theme}`]: theme,
        'signup-popup--big-image': restPopupProps.isBigImageStyle,
      })}
    >
      <div className="signup-popup__container" ref={containerRef}>
        <PopupContent {...restPopupProps} screen={screen} onCloseModal={onCloseModal} />
      </div>
    </section>
  ) : null;
};

export const query = graphql`
  fragment FragmentSignUpPopup on TSignUpPopup {
    textBeforeLogo
    textAfterLogo
    closeButton
    link {
      properties {
        ariaLabel
        link {
          name
          target
          url
        }
      }
    }
    logo {
      properties {
        imageAlt
        image {
          ...FragmentGatsbyProps
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    backgroundImage {
      properties {
        imageAlt
        image {
          ...FragmentGatsbyProps
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 850) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    backgroundImageMobile {
      properties {
        imageAlt
        image {
          ...FragmentGatsbyProps
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 768) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    productsImage {
      properties {
        imageAlt
        image {
          ...FragmentGatsbyProps
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 302) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    productsDescription
    isBigImageStyle
    isHomePageOnly
    isWithoutSessionMode
    theme
    text
    disableOnPages {
      url
    }
    withBrowserSession
    delayTimeout
  }
`;

export default SignUpPopup;
